/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { PageProps } from "gatsby";

import SEO from "../components/seo";
import NavBar from "../components/navBar";
import Footer from "../components/Footer";
import Quote from "../components/Quote";
import PrimaryButton from "../components/PrimaryButton";
import { DARK_MODE } from "../constants";

export default function WhoIveHelped(props: PageProps) {
  const mainBg = DARK_MODE ? "bg-indigo-600" : "bg-white"
  const titleText = DARK_MODE ? "text-white" : "text-indigo-600"

  return (
    <div className={mainBg} id="root">
      <SEO
        title="Who I've helped"
        description="Are you struggling with... Bone Loss, Strength, Pain?"
      />
      <NavBar {...props} />
      <main>
        <div className="relative py-16 px-4 sm:px-6 lg:px-8 overflow-hidden flex-col flex items-center">
          <div className="max-w-3xl w-full mt-2 leading-8 mb-2 sm:mb-10">
            <h3 className={titleText + " text-3xl font-extrabold tracking-tight sm:text-4xl"}>
              Who I've helped
            </h3>
          </div>
          {quotes.map((quote, index) => (
            <Quote
              title={quote.title}
              quotes={quote.quotes}
              author={quote.author}
              key={quote.title}
              index={index}
            />
          ))}
          <div className="mt-10 sm:mt-20">
            <PrimaryButton analyticsLink="TESTIMONIAL_3_FREE_CHAT" text="Free 15 min Chat" href="/chat" />
          </div>
        </div>
      </main>

      <Footer />
    </div>
  );
}

const quotes = [
  {
    title:
      "The neck pain that required regular visits to the chiro ceased to exist",
    quotes: [
      <>
        Before working with Anthony, I experienced severe neck pain and many
        asthma flare-ups. Additionally,{" "}
        <b>I felt sluggish and unbalanced physically & nutritionally</b>.
      </>,
      <>
      <b>I thought this was going to be the way of life as one ages.</b>
      {" "}Therefore, inhalers, Tylenol, and massage therapy became part of my routine regimen. During severe episodes of asthma, rounds of antibiotics, steroids, and breathing treatments were required. It was around the same time when I sadly retired a set of 8-pound dumbbells after an initial misuse (not knowing what I was doing, I had strained my neck and back). After consulting with Anthony, he assured me that I would graduate past the 8-pounders. Due to my lack of skills and strength, I was initially skeptical about what progress could be made. With Anthony’s expertise, coupled with commitment to consistency, I am finding myself exceeding expectations.
      </>,
      <>
        Due to targeted exercises and breathwork these past two years,{" "}
        <b>
          the neck pain that required regular visits to the chiro ceased to
          exist
        </b>
        , and I have rarely needed the use of an inhaler. There has been
        <b>significant improvement relating to asthma symptoms</b> as I have not needed
        to take any antibiotics, steroids, or breathing treatments. I feel
        stronger, more fit, and tone.
      </>,
      "Undoubtedly, I would not have been able to achieve any of this on my own so I am eternally grateful for Anthony. Overall, training with him can only be described as a life-altering journey!",
    ],
    author: "Nisha",
  },
  {
    title:
      "I was very frustrated with unexplainable pain & constantly feeling injured",
    quotes: [
      <>
        I have only great things to say about Anthony. <b>I feel like he's an old
        soul with a unique perspective on life that can help a lot of people</b>.
        Before talking to him, I was fairly stressed, worried about some
        physical setbacks I was experiencing post-injury and 
        overwhelmed with my busy life as a working mom of 3.
      </>,
      <>
        <b>I was very frustrated with unexplainable pain & constantly feeling
        injured</b> during exercise, things that{" "}
        my mainstream doctors couldn't explain to me. But although my
        focus was on the physical, we began addressing a lot on the mental side
        of things early on - which was just as much a part of my troubles as the
        physical.
      </>,
      "Anthony has a way of challenging you in a positive and constructive manner that will make you reflect on your beliefs and the reasons for why you're doing things.",
      <>
        On the physical side, Anthony directed me to good resources that taught
        me about pain in addition to sharing what he knew and reframed my
        understanding of pain completely- something I've continued to use and
        even shared with other family members. The mental topics we discussed
        and challenged correlated with the physical training we were doing as
        well - it was all cohesive.
      </>,
      <>
        Anthony is super knowledgeable about anatomy and movement in the body
        and created custom training programs that intelligently progressed me in
        strength and difficulty so that I could get back to doing what I loved.
        Through his programs, I was able to build a base and relearn movements
        that corrected my form and taught me valuable skills I still use. I was
        able to gradually progress to where I could confidently workout on my
        own again <b>without fear and pain</b> - which is exactly what I wanted
        to do.
      </>,
      <>
        Ultimately, <b>I learned a lot about myself and the mind-body relationship</b>
        while I worked with Anthony. I learned about how my fears & stress
        translated into tension in my movement, and he helped me gain back my
        confidence, strength and ability, by meeting me where I was. Anthony was
        always personable, and accessible, and I feel like{" "}
        <b>he truly cared about me and how I was doing</b> - a friend. I never
        felt like he was rushed with our time together.
      </>,
      "I definitely recommend Anthony to anyone who's looking for a unique approach to health and fitness in both body and mind.",
    ],
    author: "Mackenzie",
  },
  {
    title: "I started growing bone in my 50s and have the tests to prove it.",
    quotes: [
      "My osteopenia disappeared after 6 months with Anthony’s guidance. I literally started growing bone in my 50s and have the tests to prove it.",
    ],
    author: "Michelle",
  },
  {
    title: "He returned my shoulder to full function…something I thought I would never experience again",
    quotes: [
      <>
        <b>Working with Anthony felt like nothing short of a miracle</b>. Through his careful assessment and thoughtful progressive program, <b>he returned my shoulder to full function…something I thought I would never experience again</b>.
      </>,
      <>
        Additionally,{" "}
        <b>I got a heck of a lot stronger and more in control of my balance</b>.
        I also discovered the importance of using one’s breath. Many times, I
        reported back to Anthony the changes I noticed in my daily life, for
        example, lifting my bike onto the car rack was a great deal easier.
      </>,
      "I give a lot of credit to his careful planning, his encouraging manner, and great personality.",
    ],
    author: "Sue",
  },
];
